import React from 'react';

// Components
import { StaticImage } from 'gatsby-plugin-image';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Office(){
  const {t} = useTranslation("about");
  return <div className="bg-slate-50 py-20">
          <div className="w-full lg:max-w-3xl mx-auto text-center pb-3 md:pb-8">
            <h3 className="font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("office.title")}</h3>
            <p className="px-6 text-slate-600 my-6 leading-[1.4] tracking-wide text-lg">{t("office.description")}</p>
          </div>
          <div className="grid grid-cols-4 lg:grid-cols-6 mx-auto  max-w-7xl px-6 lg:px-3 gap-6 py-12">
            <StaticImage placeholder="blurred" 
                          layout="fullWidth" 
                          objectFit="contains" 
                          imgClassName="rounded-md" 
                          className="row-span-2 col-span-2 lg:row-span-1 lg:col-span-1" 
                          alt="office-0"
                          src={"../../images/office/gpu.jpg"}/>
            <StaticImage placeholder="blurred" 
                          layout="fullWidth" 
                          objectFit="contains" 
                          imgClassName="rounded-md" 
                          className="col-span-2 lg:row-span-1 lg:col-span-2" 
                          alt="office-1"
                          src={"../../images/office/monica-leonardi-liKvq7qTtfs-unsplash.jpg"}/>
            <StaticImage placeholder="blurred" 
                          layout="fullWidth" 
                          objectFit="contains" 
                          imgClassName="rounded-md" 
                          className="hidden lg:block  row-span-2 lg:row-span-1 lg:col-span-1 " 
                          alt="office-2"
                          src={"../../images/office/nathan-dumlao-dvuHNTJxIsg-unsplash.jpg"}/>
            <StaticImage placeholder="blurred" 
                          layout="fullWidth" 
                          objectFit="contains" 
                          imgClassName="rounded-md" 
                          className="col-span-2 lg:row-span-1 lg:col-span-2" 
                          alt="office-3"
                          src={"../../images/office/IMG_3078.jpeg"}/>
          </div>
         </div>
}