import React from 'react';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Hooks
import { SectionTitle } from 'components/core/typo';
import { StaticImage } from 'gatsby-plugin-image';
import {FaTwitter, FaLinkedinIn} from "react-icons/fa";

function Member({name, twitter, linkedin, children}){
  const {t} = useTranslation("about");
  return <div>
          <div className="shadow-lg rounded-md overflow-hidden aspect-square h-48">
            {children}
          </div>
          <h3 className="mt-5 font-medium">{name}</h3>
          <p className="text-slate-800 ">{t(name+".role")}</p>
          <p className="text-slate-500 mt-2 ">{t(name+".bio")}</p>
          <div className="flex space-x-2 mt-3 text-xl ">
            {twitter && <a href={twitter} className="text-decoration-none text-slate-400 hover:opacity-70 mr-4">
                <FaTwitter/>
              </a>}
              {linkedin &&<a href={linkedin} className="text-decoration-none text-slate-400 hover:opacity-70">
                <FaLinkedinIn/>
              </a>}
            </div>
          </div>
}

export function Team(){
  const {t} = useTranslation("about");
  return <div >
          <SectionTitle title={t("team.title")}/>
          <div className="py-16 grid md:grid-cols-2 xl:grid-cols-3 gap-20 max-w-5xl xl:max-w-7xl px-6 lg:px-12 mx-auto">
            <Member name="Martin Laprise"
                    twitter={"https://twitter.com/mlaprise"}
                    linkedin={"https://www.linkedin.com/in/martinlaprise/"}>
              <StaticImage src={"../../images/members/martin-laprise.jpg"} alt="Martin Laprise" imgClassName="rounded-md"/>
            </Member>

            <Member name="Edward Laurence"
                    linkedin="https://www.linkedin.com/in/edward-laurence-92398b141/">
              <StaticImage src={"../../images/members/edward-laurence4.jpg"} alt="Edward Laurence" imgClassName="rounded-md"/>
            </Member>

            <Member name="Charles Murphy"
                    twitter="https://twitter.com/cha_murph">
              <StaticImage src={"../../images/members/charles-murphy.jpg"} alt="Charles Murphy" imgClassName="rounded-md"/>
            </Member>

            <Member name="Jonathan Gingras"
                    linkedin="https://www.linkedin.com/in/jonathan-gingras-ouimet/">
              <StaticImage src={"../../images/members/jonathan-gingras.jpg"} alt="Jonathan Gingras" imgClassName="rounded-md"/>
            </Member>
            
            <Member name="Albert Dang-Vu"
                    linkedin="https://www.linkedin.com/in/albertdv/">
              <StaticImage src={"../../images/members/albert-dang-vu.jpg"} alt="Albert Dang-Vu" imgClassName="rounded-md"/>
            </Member>


          </div>
         </div>
}